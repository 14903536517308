import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import "./index.css"
import data from '../data/data.json'
import $ from "jquery";
import ReactPaginate from 'react-paginate';
import search from '../images/search.svg'
import favs from '../images/heart.svg'
import share from '../images/share.svg'
import ReactTooltip from 'react-tooltip';
import { navigate } from 'gatsby'
import Shop from "../components/shop.jsx"

import ProductItem from "../components/productItem"
//import numberOfWords from "word-frequency-basic"

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  console.log(pairs)
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

class IndexPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { offset: 0, pageNr: 0, order: "POPULARITY", category: "alle", itemsPerPage: 16, allItems: data.items, favs: [],
      items: data.items.sort((a, b) => (parseInt(b["reviews-count"]) * parseInt(b["rating"])) - (parseInt(a["reviews-count"]) * parseInt(a["rating"])))
    };

    this.handleOnSearch = this.handleOnSearch.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.filterItems = this.filterItems.bind(this)
    this.sortItems = this.sortItems.bind(this)
    this.encode = this.encode.bind(this)
    this.decode = this.decode.bind(this)
    this.fallbackCopyTextToClipboard = this.fallbackCopyTextToClipboard.bind(this)
    this.updateFavs = this.updateFavs.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  componentDidMount() {
    let query = parseQuery(this.props.location.search)
    if (query.favs) {
      this.decode(query.favs)
    }
    console.log(query)
    if (query.search) {
      this.filterItems("search", query.search)
    }
    
    /*
    var text = ""
    for (let i = 0; i < data.items.length; i++) {
        const element = data.items[i];
        text += " " + element.title
    }
    let sortable = [];
    let freq = numberOfWords(text)
    for (var word in freq) {
        sortable.push({word: word, val: freq[word]});
    }
    sortable.sort(function(a, b) {
      return b.val - a.val;
    });
    */
  
    
    this.setState({pageCount: Math.ceil(data.items.length / this.state.itemsPerPage), favs: JSON.parse(localStorage.getItem("favs")) || []})
  }

  componentWillUnmount() {
  }
  handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
  }
  handlePageClick = (event) => {
    const newOffset = (event.selected * this.state.itemsPerPage) % data.items.length;
    this.setState({offset: newOffset, pageNr: event.selected});
  };
  encode = () => {
    let str = JSON.stringify(localStorage.getItem("favs"))
    let result = window.btoa(unescape(encodeURIComponent( str )));
    return result
  }
  decode = (b64) => {
    try {
      let oldFavs = JSON.parse(localStorage.getItem("favs")) || []
      let favsArr = JSON.parse(decodeURIComponent(escape(window.atob( b64 ))).replace(/\"/g, "")) || []
      for (var i = 0; i < favsArr.length; i++) {
        if (oldFavs.indexOf(favsArr[i]) === -1){
          oldFavs.push(favsArr[i])
        }
      }
      localStorage.setItem("favs", JSON.stringify(oldFavs))
      this.filterItems("favs")
    } catch (error) {
      
    }
    
  }
  filterItems = (filterType , filterText) => {
    let items = []
    if (filterType === "category") {
      if (filterText == "alle") {
        items = this.state.allItems
      } else {
        items = this.state.allItems.filter((item) => {
          return item.categories.indexOf(filterText) !== -1
        })
      }
      this.setState({category: filterText})
    }
    if (filterType === "favs") {
      let favs = JSON.parse(localStorage.getItem("favs")) || []
      items = this.state.allItems.filter((item) => {
        return favs.indexOf(item.articleNum) !== -1
      })
      this.setState({category: "alle", favs: favs})
    }
    if (filterType === "search") {
      console.log(filterText)
      items = this.state.allItems.filter((item) => {
        return item.title.toLowerCase().includes(filterText.toLowerCase())
      })
      this.setState({category: "alle"})
    }
    this.setState({offset: 0, pageNr: 0})
    this.sortItems(this.state.order, items)
  }
  sortItems = (order, currentitems) => {
    let items = currentitems || this.state.items
    console.log(items)
    let sortedItems = [];
    if (items.length > 0) {
      if (order === "ASC") {
        sortedItems = items.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      } else if (order === "DESC") {
        sortedItems = items.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      } else {
        sortedItems = items.sort((a, b) => (parseInt(b["numRating"]) * parseFloat(b["avgRating"].replace(",", "."))) - (parseInt(a["numRating"]) * parseFloat(a["avgRating"].replace(",", "."))))
      }
    }
   
    this.setState({items: sortedItems, order: order})
  }
  
  updateFavs(articleNum) {
    let favsArr = this.state.favs
    if (favsArr.indexOf(articleNum) === -1) {
        favsArr.push(articleNum)
    } else {
        favsArr.splice(favsArr.indexOf(articleNum), 1)
    }

    this.setState({favsArr})
    localStorage.setItem("favs", JSON.stringify(favsArr))
  }
  fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  onChange = (e) => {
    this.setState({searchStr: e.target.value})
  }
  onSubmit = (e) => {
    e.preventDefault()
    this.filterItems("search", this.state.searchStr)
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps.location)
    if (this.props.location.search === "" && prevProps.location.search !== "") {
      this.setState({searchStr: ""}, () => {
        this.filterItems("search", this.state.searchStr)
      })
    }
  }
  render() {
    //<img style={{ margin: "auto", width: "85%", marginLeft: 0}} src={mapCaribbean} />
    let items = this.state.items.map((item, key) => {
      return <ProductItem item={item} key={key} favs={this.state.favs} updateFavs={this.updateFavs} />
    })
    const endOffset = this.state.offset + this.state.itemsPerPage;
    let currentItems = items.slice(this.state.offset, endOffset)
    let pagecount = Math.ceil(items.length / this.state.itemsPerPage)
    let categoriesByType = []

    return (
    <>
     <Shop location={this.props.location}/>
    </>)
  }
}


export default IndexPage
